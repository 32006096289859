<template>
  <div class="contractWaybillTraceCmpt">
    <!-- 运单信息 -->
    <div class="waybillInfo">
      <div style="border-bottom: 1px solid #eee; display: flex; justify-content: space-between; align-items: center;">
        <el-button size="mini" type="primary"
          :icon="flag.showMoreWaybillInfo ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin: 8px 0;"
          @click="flag.showMoreWaybillInfo = !flag.showMoreWaybillInfo">{{ flag.showMoreWaybillInfo ? '收起' : '查看更多' }}
        </el-button>
        <img v-if="waybillInfo.Status == 2"
          src="https://fangzhongkeji.oss-cn-shenzhen.aliyuncs.com/fzkj/web/front/YSB_XCX/waitConfirm.png"
          style="width: 65px; height: 25px;">
        <img v-if="waybillInfo.Status == 5"
          src="https://fangzhongkeji.oss-cn-shenzhen.aliyuncs.com/fzkj/web/front/YSB_XCX/waitLoad.png"
          style="width: 65px; height: 25px;">
        <img v-if="waybillInfo.Status == 7"
          src="https://fangzhongkeji.oss-cn-shenzhen.aliyuncs.com/fzkj/web/front/YSB_XCX/inTransit.png"
          style="width: 65px; height: 25px;">
        <img v-if="waybillInfo.Status == 8"
          src="https://fangzhongkeji.oss-cn-shenzhen.aliyuncs.com/fzkj/web/front/YSB_XCX/completed.png"
          style="width: 65px; height: 25px;">
      </div>
      <br>
      <el-row :gutter="16" style="margin-bottom: 8px;">
        <el-col :span="5">运单号：{{ waybillInfo.OrderID }}</el-col>
        <!-- <el-col :span="5">货品价值：<span style="color: #FF8B17">￥{{ waybillInfo.GoodsValue }}</span></el-col>
        <el-col :span="5">预计装货时间：{{ waybillInfo.PlanStartDatetime }}</el-col>
        <el-col :span="5">实际装货时间：{{ waybillInfo.ActualStartDatetime }}</el-col>
        <el-col :span="4">实际卸货时间：{{ waybillInfo.PlanEndDatetime }}</el-col> -->
        <el-col :span="6">货主单位：{{ waybillInfo.OwnerCompanyName }}</el-col>
        <el-col :span="5">服务商单位：{{ waybillInfo.FacilitatorCompanyName }}</el-col>
        <el-col :span="5" v-if="waybillInfo.Status == 0">任务完成状态：新任务</el-col>
        <el-col :span="5" v-if="waybillInfo.Status == 1">任务完成状态：待服务商确认</el-col>
        <el-col :span="5" v-if="waybillInfo.Status == 2">任务完成状态：待司机确认</el-col>
        <el-col :span="5" v-if="waybillInfo.Status == 3">任务完成状态：服务商确认</el-col>
        <el-col :span="5" v-if="waybillInfo.Status == 4">任务完成状态：服务商拒绝</el-col>
        <el-col :span="5" v-if="waybillInfo.Status == 5">任务完成状态：司机确认</el-col>
        <el-col :span="5" v-if="waybillInfo.Status == 6">任务完成状态：司机拒绝</el-col>
        <el-col :span="5" v-if="waybillInfo.Status == 7">任务完成状态：运输中</el-col>
        <el-col :span="5" v-if="waybillInfo.Status == 8">任务完成状态：已完成</el-col>
        <el-col :span="3">司机姓名：{{ waybillInfo.DriverName }}</el-col>
      </el-row>
      <el-row :gutter="16" style="margin-bottom: 8px;">
        <el-col :span="5">司机手机号码：{{ waybillInfo.DriverPhone }}</el-col>
        <el-col :span="6">司机身份证号码：{{ waybillInfo.DriverIdCard }}</el-col>
        <el-col :span="5">货品名称：{{ waybillInfo.GoodsName }}</el-col>
        <el-col :span="5">货品单位：{{ waybillInfo.GoodsUnitName }}</el-col>
        <el-col :span="3">货品价值：{{ waybillInfo.GoodsValue }}</el-col>
      </el-row>
      <el-row :gutter="16" style="margin-bottom: 8px;">
        <el-col :span="5">收款人姓名：{{ waybillInfo.PayeeName }}</el-col>
        <el-col :span="6">收款人身份证号码：{{ waybillInfo.PayeeIdCard }}</el-col>
        <el-col :span="5">收款人手机号码：{{ waybillInfo.PayeePhone }}</el-col>
        <el-col :span="5">车牌：{{ waybillInfo.CarNumber }}</el-col>
        <el-col :span="3">运费：<span style="color: #FF8B17">￥{{ waybillInfo.CarrierPrice }}</span></el-col>

      </el-row>
      <div v-show="flag.showMoreWaybillInfo">
        <el-row :gutter="16" style="margin-bottom: 8px;">
          <el-col :span="6">任务创建时间：{{ waybillInfo.CreateTime }}</el-col>
          <el-col :span="6">装货时间：{{ waybillInfo.PlanStartDatetime }}</el-col>
          <el-col :span="6">签收时间：{{ waybillInfo.PlanEndDatetime }}</el-col>
          <el-col :span="3">司机装货数量：{{ waybillInfo.LoadCount }}</el-col>
        </el-row>
        <el-row :gutter="16" style="margin-bottom: 8px;">
          <el-col :span="6">司机运输单价（人民币）：￥{{ waybillInfo.driverCarryPrice }}</el-col>
          <el-col :span="9">起始地：{{ waybillInfo.StartAddress }}</el-col>
          <el-col :span="9">到达地：{{ waybillInfo.EndAddress }}</el-col>
        </el-row>
      </div>
    </div>

    <div id="container" @click.stop="flag.recordDialog = false"></div>

    <!-- 操作面板 -->
    <div class="operation" v-if="trackList.length > 0">
      <el-button tpye="primary" size="medium" icon="el-icon-refresh" @click="startMove">重头开始移动</el-button>
      <el-button tpye="primary" size="medium" icon="el-icon-video-play" @click="pauseMove">暂停移动</el-button>
      <el-button tpye="primary" size="medium" icon="el-icon-video-pause" @click="resumeMove">恢复移动</el-button>
    </div>

    <!-- 点击事件显示的面板 -->
    <div id="pointPanel" style="display: none">
      <strong>途中打卡</strong>
      <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">打卡时间：</span>{{
          pointInfo.time
      }}</p>
      <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">打卡地址：</span>{{
          pointInfo.address
      }}</p>
    </div>

    <!-- 打卡记录按钮 -->
    <div class="record" @click="openRecordDialog">
      打卡记录
    </div>

    <!-- 打开记录列表 -->
    <div class="recordList" v-if="flag.recordDialog && trackList.length > 0">
      <div class="box">
        <el-table :data="trackList" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" max-height="400">
          <el-table-column align="center" prop="RecordType" label="" show-overflow-tooltip>
            <template slot-scope="scope">
              <img v-if="scope.row.RecordType == 0"
                src="https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png" />
              <p v-if="scope.row.RecordType == 1" src="https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png">
                {{ scope.row.index }}</p>
              <img v-if="scope.row.RecordType == 2" src="https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png" />
            </template>
          </el-table-column>
          <el-table-column align="center" prop="RecordTime" label="打卡时间" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="RecordType" label="打卡类型" show-overflow-tooltip>
            <template slot-scope="scope">
              <p v-if="scope.row.RecordType == 0">装货打卡</p>
              <p v-if="scope.row.RecordType == 1">途中打卡</p>
              <p v-if="scope.row.RecordType == 2">卸货打卡</p>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="RecordType" label="操作" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#0F5FFF; cursor: pointer" @click="openVoucherDialog(scope.row)">详情</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 上传凭证dialog -->
    <el-dialog class="voucherDialog" width="1200px" title="凭证" center :visible.sync="flag.voucherDialog" append-to-body>
      <div style="display: flex; margin-bottom: 10px;">
        <div v-for="item in this.currentVoucherItem.Evidences" :key="item.EvidenceId">
          <!-- 名称 -->
          <p :class="{ activeTab: item.EvidenceId == flag.voucherTabActive }" @click="voucherTabChange(item)"
            style="border: 1px solid #efefef; text-align: center; border-radius: 3px; padding: 8px 16px; margin: 0 4px; cursor: pointer; font-size: 15px;">
            {{ item.EvidenceName }}</p>
        </div>
      </div>
      <!-- 图片列表 -->
      <div style="margin-top: 16px;">
        <div v-for="el in this.currentImgList" :key="el">
          <el-image style="width: 200px; height: 100px; margin-bottom: 10px;" :src="el" :preview-src-list="[el]">
          </el-image>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { reverseCoord } from '@/api/common/common'
export default {
  props: {
    waybillInfo: { //运单信息
      type: Object,
      default() {
        return {}
      }
    },
    pagetype: { // 页面类型
      type: String,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      flag: {
        voucherTabActive: '', //当前激活凭证的索引
        loadWaybillInfo: false, //加载运单信息
        recordDialog: false, //打卡记录弹窗
        showMoreWaybillInfo: false, //显示更多运单信息
        voucherDialog: false, //凭证弹窗
      },
      marker: null, //地图句柄
      speed: 5000, //移动速度,
      pointInfo: { //点击事件面板
        address: '', //地址
        time: '', //时间
      },
      trackList: [], //轨迹列表
      start: null, //轨迹起点
      end: null, //轨迹重点
      currentVoucherItem: {}, //当前操作的表格凭证节点
      currentImgList: [], //当前表格凭证的图片
    }
  },
  methods: {
    init() {
      let self = this
      //初始化地图
      let map = new TMap.Map("container", {
        zoom: 13,
        center: this.center
      })

      let polylineLayer = new TMap.MultiPolyline({
        map, // 绘制到目标地图
        // 折线样式定义
        styles: {
          'style_blue': new TMap.PolylineStyle({
            'color': '#3777FF', //线填充色
            'width': 4, //折线宽度
            'borderWidth': 2, //边线宽度
            'borderColor': '#FFF', //边线颜色
            'lineCap': 'round' //线端头方式
          })
        },
        geometries: [{
          styleId: 'style_blue',
          paths: this.path
        }],
      })

      this.marker = new TMap.MultiMarker({
        map,
        styles: {
          'car-down': new TMap.MarkerStyle({
            'width': 30,
            'height': 60,
            'anchor': {
              x: 30 / 2,
              y: 60 / 2,
            },
            'faceTo': 'map',
            'rotate': 180,
            'src': 'https://res.gts56.com/fzkj/web/front/YSB_PC/trackCar.png',
          }),
          "start": new TMap.MarkerStyle({
            "width": 25,
            "height": 35,
            "anchor": { x: 16, y: 32 },
            "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png'
          }),
          "end": new TMap.MarkerStyle({
            "width": 25,
            "height": 35,
            "anchor": { x: 16, y: 32 },
            "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png'
          }),
          "point": new TMap.MarkerStyle({
            "width": 18,  // 点标记样式宽度（像素）
            "height": 25, // 点标记样式高度（像素）
            // "src": 'https://img2.baidu.com/it/u=394445974,1839509839&fm=26&fmt=auto&gp=0.jpg',  //图片路径
            "src": require('@/assets/image/2.png'),  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            "anchor": { x: 16, y: 32 }
          })
        },
        geometries: [
          {
            id: 'car',
            styleId: 'car-down',
            position: new TMap.LatLng(0, 0),
          },
          {
            id: 'start',
            styleId: 'start',
            position: this.start
          },
          {
            id: 'end',
            styleId: 'end',
            position: this.end
          },
          ...this.points
        ]
      })
      //初始化mark标记点点击事件
      var infoWindow = new TMap.InfoWindow({
        map: map,
        position: new TMap.LatLng(0, 0),
        offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      })
      infoWindow.close() //初始关闭信息窗关闭
      //监听标注点击事件
      this.marker.on("click", function (evt) {
        //不处理起点和重点
        if (evt.geometry.styleId == 'start' || evt.geometry.styleId == 'end') return
        //经纬度逆解析
        let params = {
          lat: evt.geometry.position.lat,
          lng: evt.geometry.position.lng
        }
        reverseCoord(params).then(res => {
          //设置infoWindow
          infoWindow.open()  //打开信息窗
          infoWindow.setPosition(evt.geometry.position) //设置信息窗位置
          //设置信息窗内容
          self.pointInfo.address = res.data.address //地址
          self.pointInfo.time = evt.geometry.properties.time //时间
          self.$nextTick(() => {
            infoWindow.setContent(document.getElementById('pointPanel').innerHTML)
          })
        })
      })
      this.startMove()
    },
    //重头开始移动
    startMove() {
      this.marker.moveAlong({
        'car': {
          path: this.path,
          speed: this.speed
        }
      }, {
        autoRotation: true
      })
    },
    //停止移动
    pauseMove() {
      this.marker.pauseMove()
    },
    //重新开始
    resumeMove() {
      this.marker.resumeMove()
    },
    //打开打卡记录框
    openRecordDialog() {
      if (this.trackList.length === 0) {
        this.$message.warning('暂无打卡记录')
        return
      }
      this.flag.recordDialog = !this.flag.recordDialog
    },
    //打开打卡记录详情框
    openVoucherDialog(e) {
      if (e.Evidences.length === 0) {
        this.$message.error('暂无凭证')
        return
      }
      this.currentVoucherItem = e
      this.flag.voucherTabActive = e.Evidences[0].EvidenceId
      this.currentImgList = e.Evidences[0].imgUrls
      this.flag.voucherDialog = true
    },
    //凭证索引改变回调
    voucherTabChange(e) {
      this.currentImgList = e.imgUrls
      this.flag.voucherTabActive = e.EvidenceId
    }
  },
  computed: {
    //轨迹路线
    path() {
      return this.trackList.map(item => {
        return new TMap.LatLng(item.AddressLat, item.AddressLon)
      })
    },
    //标注点路线
    points() {
      let arr = []
      this.trackList.forEach((item, index) => {
        arr.push({
          id: index + 2,
          styleId: 'point',
          position: new TMap.LatLng(item.AddressLat, item.AddressLon),
          properties: {//自定义属性
            time: item.CreateTime,
          }
        })
      })
      return arr
    },
    //地图中心
    center() {
      if (this.path.length == 0) return
      return this.path[parseInt(Number(this.path.length) / 2)]
    },
  },
  watch: {
    waybillInfo(val) {
      this.waybillInfo = val
      this.trackList = val.Records
      if (this.trackList.length > 0) {
        //获取轨迹起点和重，并且排序起点放到最前面，终点放到最后面
        this.trackList.forEach((item, index) => {
          if (item.RecordType == 0) { //起点
            this.start = new TMap.LatLng(item.AddressLat, item.AddressLon)
            this.trackList.splice(index, 1)
            this.trackList.unshift(item)
          } else if (item.RecordType == 2) { //终点
            this.end = new TMap.LatLng(item.AddressLat, item.AddressLon)
            this.trackList.splice(index, 1)
            this.trackList.push(item)
          }
        })
        //生成index 
        this.trackList.forEach((item, index) => {
          item.index = index
        })
        //绘制地图
        this.init()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.contractWaybillTraceCmpt {
  min-width: 1200px;
  width: 100%;
  position: relative;

  .waybillInfo {
    width: 88%;
    z-index: 9999;
    position: absolute;
    top: 8px;
    left: 50px;
    background: white;
    border-radius: 4px;
    padding: 0 8px;
    box-sizing: border-box;
  }

  #container {
    height: 750px;
    margin: 0 auto;
  }

  .operation {
    width: 100%;
    margin: 30px 0;
    z-index: 99999;
    border-radius: 5px;
    background: white;
    display: flex;
    justify-content: center;
  }

  .record {
    width: 35px;
    height: 400px;
    position: absolute;
    top: 200px;
    left: 0px;
    margin: 0 auto;
    text-align: center;
    z-index: 9999;
    font-size: 18px;
    font-weight: 600;
    line-height: 100px;
    cursor: pointer;
    background: #2081ff;
    color: white;
  }

  .recordList {
    position: absolute;
    left: 35px;
    top: 200px;
    width: 500px;
    height: 400px;
    background: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 9999;

    .box {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}

.voucherDialog {
  .activeTab {
    background: #409eff;
    color: white;
  }
}

.voucherWrap {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  width: 1200px;

  .item {
    width: 19%;
    margin: 16px 0;
    position: relative;
  }
}
</style>
