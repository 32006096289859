import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//获取运单列表
export const getOrders = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/HTWL/GetOrders`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//指派司机
export const assignDriver = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/HTWL/AssignDriver`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//指派车辆
export const assignCar = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/HTWL/AssignCar`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//修改订单状态
export const updateOrderStatus = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/HTWL/UpdateOrderStatus`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查询合作伙伴
export const getPartnerList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/HTWL/GetPartnerList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//发往运单库
export const goWaybill = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/HTWL/GoWaybill`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询运单详情
export const getOrderDetail = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/HTWL/GetOrderDetail?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}