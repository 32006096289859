import axios from '@/api/config/interceptor'
import {
    host
} from '@/api/config/host'
import {
    _paramsToQueryString
} from '@/utils/utils'

//通过运单号获取运单详情
export const getOrderDetail = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/HTWL/GetOrderDetail?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


//通过运单号获取运单详情
export const GetHtwlFacilitatorIDAsync = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/gtw/api/v2/HTWL/GetHtwlFacilitatorIDAsync?importBatchId=${params}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}